import React from 'react'
import Layout from '../../components/layout'

import BackgroundImage from 'gatsby-background-image'

import { Link, useStaticQuery, graphql } from 'gatsby'
import LightButton from '../../components/buttons/light_button'

const CasesTemplate = () => {
  const data = useStaticQuery(graphql`
    query Casestemplate {
      bgImage: file(relativePath: { eq: "blog/bg-page-title.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout bgColor>
      <PageHeader
        className="pink"
        title="CLOUD MIGRATION SAVES MONEY FOR HEALTH INSURER"
        background={data.bgImage.childImageSharp.fluid}
        previousPage="Products"
        previousPageLink="/products/"
        thisPage="CLOUD MIGRATION SAVES MONEY FOR HEALTH INSURER"
      />
      <div className="container cases-project-image pb-5">
        <div
          style={{
            border: '15px solid #fff',
            position: 'relative',
            zIndex: 10,
            backgroundColor: '#fff'
          }}
        >
          <img
            style={{
              marginTop: -270,
              width: '100%',
              position: 'relative'
            }}
            src=""
            alt=""
          />
        </div>
      </div>
      <div className="col-md-6 pt-5 mx-auto">
        <div className="row">
          <div className="col-md-3 text-left text-md-center">
            <h4 className="m-0">Overview</h4>
          </div>
          <div className="col-md-9">
            <p>
              As a global player in the telecoms industry, Vodafone’s reputation
              thrives on their ability to innovate and adapt to market trends.
              To that end, the Marketing Academy in Vodafone was seeking a
              relevant, in-depth and effective professional education course to
              sharpen the digital skills of their workforce.
            </p>

            <ul style={{ listStyle: 'none', marginLeft: 0, paddingLeft: 0 }}>
              <li style={{ paddingBottom: 5 }}>Client: Vodafone </li>
              <li style={{ paddingBottom: 5 }}>Industry: Retail Industry</li>
              <li style={{ paddingBottom: 5 }}>
                Service: IT Consultancy, Cloud Computing and Cyber Security
              </li>
              <li style={{ paddingBottom: 5 }}>
                Via:{' '}
                <Link className="link" to="">
                  digitalmarketinginstitute.com
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="row pt-5">
          <div className="col-md-3 text-left text-md-center">
            <h4 className="m-0">Challenge</h4>
          </div>
          <div className="col-md-9">
            <p>
              As Head of Global and Commercial Learning and Development at
              Vodafone, Mohsin Ghafoor’s task was to raise standards in digital
              marketing, across the group.
              <br />
              <br />
              “Our aim was that every marketer at Vodafone reaches what we would
              describe as a minimum acceptable standard of digital marketing
              skills, knowledge, and capability, and be able to apply that to
              their day job and have an impact on our customers.”
              <br />
              <br />
              But not only does Vodafone have a very large workforce, it is also
              spread all over the world. Natasha Brookes is a Learning and
              Development Specialist at Vodafone. For her, it was clear that a
              digital marketing course needed an online solution.
              <br />
              <br />
              “Vodafone is a large global organization. In order to reach all of
              the marketers within the different markets, what better way to do
              it than having one place where marketers can come and learn? And
              it will be a consistent learning approach.”
            </p>
          </div>
        </div>
        <div className="row pt-5">
          <div className="col-md-3  text-left text-md-center">
            <h4 className="m-0">Solution</h4>
          </div>
          <div className="col-md-9">
            <p>
              The Digital Marketing Institute realized early on that Vodafone is
              a company that wants to set the pace, not just keep up with it.
              They didn’t just want a foundation to their digital marketing,
              they also wanted to train their staff to be up-to-date and current
              in their knowledge and skills, so they could anticipate what their
              customers would want.
              <br />
              <br />
              “I had the greatest degree of confidence in the Digital Marketing
              Institute, both in terms of quality of content and their global
              certification standard, which we could apply across all of our
              markets,” stated Ghafoor
              <br />
              <br />
              Fortunately, the Vodafone Marketing Academy had a learning
              management system, or e-learning system, already set up. Having a
              learning management system meant that their staff already knew the
              benefits of learning online. The next step was to find the right
              partner, to provide the content the company needed.
              <br />
              <br />
              To get Vodafone started, The Digital Marketing Institute began by
              carefully picking topics and courses that were most relevant to
              Vodafone and their staff. They then agreed a global user license
              that gave Vodafone’s staff access to 15 of the DMI modules. Their
              staff could choose from modules in both the Professional Diploma
              in Digital Marketing and from the Specialist Diplomas.
            </p>
          </div>
        </div>
        <div className="row pt-5 pb-5">
          <div className="col-md-3  text-left text-md-center">
            <h4 className="m-0">Results</h4>
          </div>
          <div className="col-md-9 pb-5">
            <p>
              The Digital Marketing Institute team then worked with Vodafone to
              integrate core modules and content into their e-learning system to
              make it easy for their global workforce to train together,
              wherever they were, and whenever it suited. The video-based
              learning content is now used by Vodafone Marketing Academy staff
              all over the world.
              <br />
              <br />
              A launch was also successfully supported by with a range of
              creative promotions – from e-flyers to newsletters and helped
              generate awareness and enthusiasm for the courses through internal
              campaigns. Today, The Digital Marketing Institute continues to
              work with Vodafone to certify their workforce, and help the
              company realize their full digital potential.
              <br />
              <br />
              “I found the relationship to be a really positive one. One that
              was constructive. It certainly felt to me that what I was saying
              about Vodafone’s needs and requirements in this space was being
              listened to,” concluded Mohsin.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

const PageHeader = ({
  background,
  title,
  subtitle,
  buttonTitle,
  buttonLink,
  thisPage,
  className,
  children,
  previousPage,
  previousPageLink
}) => {
  return (
    <BackgroundImage fluid={background} className={`page-header ${className}`}>
      <div className={`overlay-page-header ${className}`}></div>
      <div className="page-header-inner wrap">
        <div className="col-md-5 mx-auto">
          <div className="project-client-circle">
            <img src="" alt="" />
            <span
              style={{ backgroundColor: '#71cbcc' }}
              className="mask"
            ></span>
          </div>
          <div className="page-title">
            <h2>{title}</h2>
          </div>
          <div className="page-subtitle">
            <p>{subtitle}</p>
          </div>
          {buttonTitle
            ? (
            <div>
              <div style={{ height: 30 }}></div>
              <LightButton title={buttonTitle} />
            </div>
              )
            : null}

          <div style={{ height: 30 }}></div>
          <div className="breadcrumb-explorelogy">
            <div className="breadcrumb-inner">
              <span>
                <Link to="/">Explorelogy</Link>
              </span>

              <span className="link-item">
                <Link to={previousPageLink}>{previousPage}</Link>
              </span>

              <span>{thisPage}</span>
            </div>
          </div>
          <div style={{ height: 300 }}></div>
          {children}
        </div>
      </div>
    </BackgroundImage>
  )
}

export default CasesTemplate
